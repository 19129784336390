import i18n from 'i18next';
import * as _ from 'lodash';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';
import { Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { EventTypes, FeatureState } from '../../global.d';
import {
  setBetAmount,
  setCoinAmount,
  setCurrency,
  setIsBuyFeaturePopupOpened,
  setMaxLevelFreeSpins,
  setSlotConfig,
} from '../../gql/cache';
import { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { ResourceTypes } from '../../resources.d';
import {
  countCoins,
  getMaxCoinAmount,
  getNormalCoinAmount,
  normalizeCoins,
  showCurrency,
  updateTextScale,
} from '../../utils';
import { FeatureButton } from '../components/FeatureButton';
import { SpriteButtonState } from '../components/SpriteButton';
import {
  FEATURE_POPUP_AMOUNT_TEXT_POSITION_X,
  FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y,
  FEATURE_POPUP_BB_BTN_POS_X,
  FEATURE_POPUP_BB_BTN_POS_Y,
  FEATURE_POPUP_BB_COST_POS_X,
  FEATURE_POPUP_BB_COST_POS_Y,
  FEATURE_POPUP_BB_TITLE_POS_X,
  FEATURE_POPUP_BB_TITLE_POS_Y,
  FEATURE_POPUP_BET_VALUE_POSITION_X,
  FEATURE_POPUP_BET_VALUE_POSITION_Y,
  FEATURE_POPUP_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_HBB_BTN_POS_X,
  FEATURE_POPUP_HBB_BTN_POS_Y,
  FEATURE_POPUP_HBB_COST_POS_X,
  FEATURE_POPUP_HBB_COST_POS_Y,
  FEATURE_POPUP_HBB_TITLE_POS_X,
  FEATURE_POPUP_HBB_TITLE_POS_Y,
  FEATURE_POPUP_INPUT_HEIGHT,
  FEATURE_POPUP_INPUT_POSITION_X,
  FEATURE_POPUP_INPUT_POSITION_Y,
  FEATURE_POPUP_INPUT_WIDTH,
  FEATURE_POPUP_MAX_LEVEL_TITLE_POS_X,
  FEATURE_POPUP_MAX_LEVEL_TITLE_POS_Y,
  FEATURE_POPUP_MINUS_BTN_HEIGHT,
  FEATURE_POPUP_MINUS_BTN_POSITION_X,
  FEATURE_POPUP_MINUS_BTN_POSITION_Y,
  FEATURE_POPUP_MINUS_BTN_WIDTH,
  FEATURE_POPUP_OK_BTN_POSITION_X,
  FEATURE_POPUP_OK_BTN_POSITION_Y,
  FEATURE_POPUP_PLUS_BTN_HEIGHT,
  FEATURE_POPUP_PLUS_BTN_POSITION_X,
  FEATURE_POPUP_PLUS_BTN_POSITION_Y,
  FEATURE_POPUP_PLUS_BTN_WIDTH,
  FEATURE_POPUP_TITLE_POSITION_X,
  FEATURE_POPUP_TITLE_POSITION_Y,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  eventManager,
} from '../config';

import {
  BUY_FEATURE_BTN_TITLE_TEXT_STYLE,
  BUY_FEATURE_COST_TEXT_STYLE,
  BUY_FEATURE_MAX_BTN,
  BUY_FEATURE_MAX_LEVEL_TEXT_STYLE,
  BUY_FEATURE_NORMAL_BTN,
  BtnType,
} from '.';
import {
  amountTextStyle,
  betValueStyle,
  buyFeatureBtnTitleDisableStyle,
  buyFeatureBtnTitleStyle,
  buyFeatureMaxLevelTitleDisableStyle,
  buyFeatureTitleStyle,
  totalCostTextAmountDisableStyle,
  totalCostTextAmountStyle,
} from './textStyles';

class BuyFeaturePopup extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private okBtn: PIXI.Sprite;

  private cancelBtn: PIXI.Sprite;

  private titleText: PIXI.Text;

  private amountText: PIXI.Text;

  private minusBtn: PIXI.Sprite;

  private plusBtn: PIXI.Sprite;

  private input: PIXI.Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private linesAmount: number;

  private currency = 'FUN';

  private betValue: PIXI.Text;

  private btlNormalBtn: FeatureButton;

  private btlMaxBtn: FeatureButton;

  private titleNormalText: PIXI.Text;

  private titleMaxText: PIXI.Text;

  private normalCostTextAmount: PIXI.Text;

  private maxCostTextAmount: PIXI.Text;

  private featureState: FeatureState = FeatureState.NORMAL;

  private maxLevelText: PIXI.Text;

  isNoFunds: boolean;

  balance: number;

  constructor(_lines: number[]) {
    super();
    this.betSettings = getBetsSetting();
    this.visible = false;
    this.linesAmount = setSlotConfig().lineSet.coinAmountMultiplier;
    this.balance = 0;
    this.isNoFunds = false;
    this.interactive = true;
    this.currency = setCurrency();

    this.betAmount = this.getBetAmount(setBetAmount());

    this.btlNormalBtn = this.initNormalModeBtn();
    this.btlMaxBtn = this.initMaxModeBtn();
    this.titleNormalText = this.initNormalTitle();
    this.titleMaxText = this.initMaxTitle();
    this.maxLevelText = this.initMaxLevelTitle();
    this.normalCostTextAmount = this.initNormalCostTextAmount();
    this.maxCostTextAmount = this.initMaxCostTextAmount();

    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.amountText = this.initAmountText();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.input = this.initInput();
    this.betValue = this.initBetValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();

    this.init();
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.closeAllAnimationsInSlot();
      this.visible = true;
      setIsBuyFeaturePopupOpened(true);
    });
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.visible = false;
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      setIsBuyFeaturePopupOpened(false);
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance?: { currency: string; amount: number }) => {
      this.balance = balance!.amount / 100;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP_BG, () => {
      this.visible = true;
    });

    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.visible = false;
    });
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText);
    this.addChild(this.amountText);
    this.addChild(this.minusBtn);
    this.addChild(this.plusBtn);
    this.addChild(this.input);
    this.addChild(this.betValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);

    this.addChild(this.btlNormalBtn);
    this.addChild(this.btlMaxBtn);
    this.addChild(this.titleNormalText);
    this.addChild(this.titleMaxText);
    this.addChild(this.normalCostTextAmount);
    this.addChild(this.maxCostTextAmount);
    this.addChild(this.maxLevelText);
  }

  private initNormalModeBtn(): FeatureButton {
    const normalBtn = new FeatureButton({
      [SpriteButtonState.ACTIVE]: {
        texture: Texture.from(ResourceTypes.buyFeatureFsBtn),
      },
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureFsBtnNotActive),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureFsBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureFsBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureFsBtnNotActive),
      },
      onHover: this.handleBaseBtnHover.bind(this),
      onClick: this.handleBaseBtnClick.bind(this),
      onTouchStart: this.handleBaseBtnClick.bind(this),
      onMouseOut: this.handleBaseBtnMouseOut.bind(this),
      isActive: true,
    });
    normalBtn.y = FEATURE_POPUP_BB_BTN_POS_Y;
    normalBtn.x = FEATURE_POPUP_BB_BTN_POS_X;
    normalBtn.anchor.x = 0.5;

    return normalBtn;
  }

  private initMaxModeBtn(): FeatureButton {
    const maxBtn = new FeatureButton({
      [SpriteButtonState.ACTIVE]: {
        texture: Texture.from(ResourceTypes.buyFeatureMlfsBtn),
      },
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureMlfsBtnNotActive),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureMlfsBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureMlfsBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureMlfsBtnNotActive),
      },
      onHover: this.handleRageBtnHover.bind(this),
      onClick: this.handleRageBtnClick.bind(this),
      onTouchStart: this.handleRageBtnClick.bind(this),
      onMouseOut: this.handleRageBtnMouseOut.bind(this),
    });
    maxBtn.y = FEATURE_POPUP_HBB_BTN_POS_Y;
    maxBtn.x = FEATURE_POPUP_HBB_BTN_POS_X;
    maxBtn.anchor.x = 0.5;

    return maxBtn;
  }

  private initNormalTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t<string>('buyFreeSpins_FreeSpin'), buyFeatureBtnTitleStyle);
    title.resolution = 1;
    title.x = FEATURE_POPUP_BB_TITLE_POS_X;
    title.y = FEATURE_POPUP_BB_TITLE_POS_Y;
    updateTextScale(title, this.btlNormalBtn.width - 70, isMobile ? 450 : 300);
    title.anchor.set(0.5, 0);
    return title;
  }

  private initMaxTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t<string>('buyFreeSpins_MaxLevelFreeSpin'), buyFeatureBtnTitleDisableStyle);
    title.resolution = 1;
    title.x = FEATURE_POPUP_HBB_TITLE_POS_X;
    title.y = FEATURE_POPUP_HBB_TITLE_POS_Y;
    updateTextScale(title, this.btlNormalBtn.width - 70, isMobile ? 450 : 300);
    title.anchor.set(0.5, 0);
    return title;
  }

  private initMaxLevelTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t<string>('freeSpinsIndicatorMaxLevel'), buyFeatureMaxLevelTitleDisableStyle);
    title.resolution = 1;
    title.x = FEATURE_POPUP_MAX_LEVEL_TITLE_POS_X;
    title.y = FEATURE_POPUP_MAX_LEVEL_TITLE_POS_Y;
    updateTextScale(title, this.btlNormalBtn.width - 70, isMobile ? 450 : 300);
    title.anchor.set(0.5, 0.5);
    return title;
  }

  private handleBaseBtnMouseOut(): void {
    this.titleNormalText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[1]!;
    this.normalCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[1]!;
    AudioApi.play({ type: ISongs.SFX_UI_Hover });
  }

  private handleRageBtnMouseOut(): void {
    this.titleMaxText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[1]!;
    this.maxLevelText.style = BUY_FEATURE_MAX_LEVEL_TEXT_STYLE[1]!;
    this.maxCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[1]!;
    AudioApi.play({ type: ISongs.SFX_UI_Hover });
  }

  private handleBaseBtnHover(): void {
    this.titleNormalText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[0]!;
    this.normalCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[0]!;
    AudioApi.play({ type: ISongs.SFX_UI_Hover });
  }

  private handleRageBtnHover(): void {
    this.titleMaxText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[0]!;
    this.maxLevelText.style = BUY_FEATURE_MAX_LEVEL_TEXT_STYLE[0]!;
    this.maxCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[0]!;
    AudioApi.play({ type: ISongs.SFX_UI_Hover });
  }

  private handleBaseBtnClick(): void {
    this.changeFeatureState(FeatureState.NORMAL);
    this.btnActive(BtnType.NormalBtn);
    setMaxLevelFreeSpins(false);
    this.btlMaxBtn.turnOff();
    this.btlNormalBtn.turnOn();
    this.updateBets();
  }

  private handleRageBtnClick(): void {
    this.changeFeatureState(FeatureState.MAX);
    this.btnActive(BtnType.MaxBtn);
    setMaxLevelFreeSpins(true);
    this.btlMaxBtn.turnOn();
    this.btlNormalBtn.turnOff();
    this.updateBets();
  }

  private btnActive(btn: BtnType) {
    if (setMaxLevelFreeSpins() && btn == BtnType.MaxBtn) {
      return;
    } else if (!setMaxLevelFreeSpins() && btn == BtnType.NormalBtn) {
      return;
    }
    setMaxLevelFreeSpins(!setMaxLevelFreeSpins());

    this.btlNormalBtn.texture = PIXI.Texture.from(BUY_FEATURE_NORMAL_BTN[setMaxLevelFreeSpins() ? 1 : 0]!);
    this.btlMaxBtn.texture = PIXI.Texture.from(BUY_FEATURE_MAX_BTN[setMaxLevelFreeSpins() ? 0 : 1]!);

    this.titleNormalText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[setMaxLevelFreeSpins() ? 1 : 0]!;
    this.normalCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[setMaxLevelFreeSpins() ? 1 : 0]!;
    this.titleMaxText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[setMaxLevelFreeSpins() ? 0 : 1]!;
    this.maxLevelText.style = BUY_FEATURE_MAX_LEVEL_TEXT_STYLE[setMaxLevelFreeSpins() ? 0 : 1]!;
    this.maxCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[setMaxLevelFreeSpins() ? 0 : 1]!;

    this.handleDisable();
  }

  private initNormalCostTextAmount(): PIXI.Text {
    const totalCostTextAmount = new PIXI.Text(this.getNormalTotalCost(), totalCostTextAmountStyle);
    totalCostTextAmount.resolution = 1;
    totalCostTextAmount.x = FEATURE_POPUP_BB_COST_POS_X;
    totalCostTextAmount.y = FEATURE_POPUP_BB_COST_POS_Y;
    totalCostTextAmount.anchor.set(0.5);
    updateTextScale(totalCostTextAmount, this.btlNormalBtn.width - 60, 300);

    return totalCostTextAmount;
  }

  private initMaxCostTextAmount(): PIXI.Text {
    const totalCostTextAmount = new PIXI.Text(this.getMaxTotalCost(), totalCostTextAmountDisableStyle);
    totalCostTextAmount.resolution = 1;
    totalCostTextAmount.x = FEATURE_POPUP_HBB_COST_POS_X;
    totalCostTextAmount.y = FEATURE_POPUP_HBB_COST_POS_Y;
    totalCostTextAmount.anchor.set(0.5);
    updateTextScale(totalCostTextAmount, this.btlMaxBtn.width - 60, 300);

    return totalCostTextAmount;
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopupConfirm));
    popupBg.anchor.set(0.5, 0.5);
    popupBg.position.set(GAME_CONTAINER_WIDTH / 2, GAME_CONTAINER_HEIGHT / 2);
    return popupBg;
  }

  private initTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t<string>('buyFeatureTitle'), buyFeatureTitleStyle);
    title.resolution = 1;
    title.y = FEATURE_POPUP_TITLE_POSITION_Y;
    title.x = FEATURE_POPUP_TITLE_POSITION_X;
    title.anchor.set(0.5, 0);
    updateTextScale(title, this.popupBg.width - 200, isMobile ? 450 : 300);
    return title;
  }

  private initAmountText(): PIXI.Text {
    const amountText = new PIXI.Text(i18n.t<string>('buyFeatureBetPerGame'), amountTextStyle);
    amountText.resolution = 1;
    amountText.y = FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y;
    amountText.x = FEATURE_POPUP_AMOUNT_TEXT_POSITION_X;
    amountText.anchor.set(0.5, 0);
    updateTextScale(amountText, 350, 250);
    return amountText;
  }

  private initMinusBtn(): PIXI.Sprite {
    const minusBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnDisable));
    minusBtn.width = FEATURE_POPUP_MINUS_BTN_WIDTH;
    minusBtn.height = FEATURE_POPUP_MINUS_BTN_HEIGHT;
    minusBtn.y = FEATURE_POPUP_MINUS_BTN_POSITION_Y;
    minusBtn.x = FEATURE_POPUP_MINUS_BTN_POSITION_X;
    minusBtn.interactive = true;
    minusBtn.buttonMode = true;
    minusBtn.anchor.set(0.5, 0);
    minusBtn.on('click', this.handleMinus);
    minusBtn.on('touchstart', this.handleMinus);

    minusBtn.addListener('mouseover', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnHover);
      }
    });
    minusBtn.addListener('mouseout', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
        this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtn);
      }
    });
    minusBtn.addListener('mousedown', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
        this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnPressed);
      }
    });
    minusBtn.addListener('mouseup', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
        this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnHover);
      }
    });

    return minusBtn;
  }

  private initPlusBtn(): PIXI.Sprite {
    const plusBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnDisable));
    plusBtn.width = FEATURE_POPUP_PLUS_BTN_WIDTH;
    plusBtn.height = FEATURE_POPUP_PLUS_BTN_HEIGHT;
    plusBtn.y = FEATURE_POPUP_PLUS_BTN_POSITION_Y;
    plusBtn.x = FEATURE_POPUP_PLUS_BTN_POSITION_X;
    plusBtn.anchor.set(0.5, 0);
    plusBtn.interactive = true;
    plusBtn.buttonMode = true;
    plusBtn.on('click', this.handlePlus);
    plusBtn.on('touchstart', this.handlePlus);

    plusBtn.addListener('mouseover', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnHover);
      }
    });
    plusBtn.addListener('mouseout', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
        this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtn);
      }
    });
    plusBtn.addListener('mousedown', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
        this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnPressed);
      }
    });
    plusBtn.addListener('mouseup', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
        this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnHover);
      }
    });

    return plusBtn;
  }

  private initInput(): PIXI.Sprite {
    const input = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureInput));
    input.width = FEATURE_POPUP_INPUT_WIDTH;
    input.height = FEATURE_POPUP_INPUT_HEIGHT;
    input.y = FEATURE_POPUP_INPUT_POSITION_Y;
    input.x = FEATURE_POPUP_INPUT_POSITION_X;
    input.anchor.set(0.5, 0);

    return input;
  }

  private initBetValue(): PIXI.Text {
    const betValue = new PIXI.Text(
      `${formatNumber({
        currency: this.currency,
        value: normalizeCoins(countCoins({ totalAmount: this.getBetValue() })),
        showCurrency: showCurrency(this.currency),
      })}`,
      betValueStyle,
    );
    betValue.resolution = 1;
    betValue.y = FEATURE_POPUP_BET_VALUE_POSITION_Y;
    betValue.x = FEATURE_POPUP_BET_VALUE_POSITION_X;
    betValue.anchor.set(0.5);
    updateTextScale(betValue, this.input.width - 30, 300);
    return betValue;
  }

  private initCancelBtn(): PIXI.Sprite {
    const cancelBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn));
    cancelBtn.y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
    cancelBtn.x = FEATURE_POPUP_CANCEL_BTN_POSITION_X;
    cancelBtn.anchor.set(0.5, 0);
    cancelBtn.interactive = true;
    cancelBtn.buttonMode = true;
    cancelBtn.on('click', () => this.onCancel());
    cancelBtn.on('touchstart', () => this.onCancel());

    cancelBtn.addListener('mouseover', () => {
      AudioApi.play({ type: ISongs.SFX_UI_Hover });
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnHover);
    });
    cancelBtn.addListener('mouseout', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn);
    });
    cancelBtn.addListener('mousedown', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnPressed);
    });
    cancelBtn.addListener('mouseup', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn);
    });
    return cancelBtn;
  }

  private onCancel() {
    AudioApi.play({ type: ISongs.SFX_UI_Close });
    this.visible = false;
    setIsBuyFeaturePopupOpened(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
  }

  private initOkBtn(): PIXI.Sprite {
    const okBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn));
    okBtn.y = FEATURE_POPUP_OK_BTN_POSITION_Y;
    okBtn.x = FEATURE_POPUP_OK_BTN_POSITION_X;
    okBtn.anchor.set(0.5, 0);
    okBtn.interactive = true;
    okBtn.buttonMode = true;
    okBtn.on('click', (): void => this.handleClickOk());
    okBtn.on('touchstart', (): void => this.handleClickOk());

    okBtn.addListener('mouseover', () => {
      if (!this.isNoFunds) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnHover);
      }
    });
    okBtn.addListener('mouseout', () => {
      if (!this.isNoFunds) {
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
      }
    });
    okBtn.addListener('mousedown', () => {
      if (!this.isNoFunds) {
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnPressed);
      }
    });
    okBtn.addListener('mouseup', () => {
      if (!this.isNoFunds) {
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
      }
    });

    return okBtn;
  }

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.linesAmount;
      }) + 1
    );
  };

  private handleMinus = (): void => {
    if (this.betAmount > 1 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
      // eslint-disable-next-line no-plusplus
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private handlePlus = (): void => {
    if (this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
      // eslint-disable-next-line no-plusplus
      this.betAmount++;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private updateBets(): void {
    this.normalCostTextAmount.text = this.getNormalTotalCost();
    updateTextScale(this.normalCostTextAmount, this.btlNormalBtn.width - 60, 300);
    this.maxCostTextAmount.text = this.getMaxTotalCost();
    updateTextScale(this.maxCostTextAmount, this.btlMaxBtn.width - 60, 300);
    this.betValue.text = `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue()),
      showCurrency: showCurrency(this.currency),
    })}`;
    updateTextScale(this.betValue, this.input.width - 30, 300);
  }

  private getBetValue = (): number => {
    return this.linesAmount * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  private handleClickOk = (): void => {
    if (!this.isNoFunds) {
      let totalCost: string;
      if (setMaxLevelFreeSpins()) {
        totalCost = this.getMaxTotalCost();
      } else {
        totalCost = this.getNormalTotalCost();
      }
      AudioApi.play({ type: ISongs.SFX_UI_General });
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP, totalCost, this.betSettings.bets[this.betAmount]!);
      this.visible = false;
    }
  };

  private handleDisable = (): void => {
    const bet = this.betSettings.bets[this.betAmount - 1];
    if (bet === this.betSettings!.minBet) {
      this.minusBtn.buttonMode = false;
      this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnDisable);
    } else {
      this.minusBtn.buttonMode = true;
      this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtn);
    }

    if (bet === this.betSettings!.maxBet) {
      this.plusBtn.buttonMode = false;
      this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnDisable);
    } else {
      this.plusBtn.buttonMode = true;
      this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtn);
    }

    let conAmount: number;
    if (setMaxLevelFreeSpins()) {
      conAmount = getMaxCoinAmount();
    } else {
      conAmount = getNormalCoinAmount();
    }

    this.isNoFunds = this.balance < normalizeCoins(this.getBetValue() * conAmount);

    if (this.isNoFunds) {
      this.okBtn.buttonMode = false;
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnDisable);
    } else {
      this.okBtn.buttonMode = true;
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
    }
  };

  private closeAllAnimationsInSlot() {
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }

  private getNormalTotalCost = (): string => {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * getNormalCoinAmount()),
      showCurrency: showCurrency(this.currency),
    })}`;
  };

  private getMaxTotalCost = (): string => {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * getMaxCoinAmount()),
      showCurrency: showCurrency(this.currency),
    })}`;
  };

  private changeFeatureState(state: FeatureState): void {
    this.featureState = state;
    AudioApi.play({ type: ISongs.SFX_UI_General });
    this.handleDisable();
  }
}

export default BuyFeaturePopup;
