// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ApolloQueryResult } from '@apollo/client';

import { BonusStatus, EventTypes, GameMode, UserBonus, bonusIds, freeRoundBonusId } from '../../global.d';
import {
  setBrokenBuyFeature,
  setBrokenGame,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setFreeRoundBonus,
  setFreeRoundsTotalWin,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { getUserBonuses } from '../../gql/query';
import { eventManager } from '../../slotMachine/config';
import { calcActiveUserBonusTotalWinAmount, getGameModeByBonusId } from '../../utils';

export const checkBrokenGame = async () => {
  const activeUserBonusData = await client.query<{
    userBonuses: UserBonus[];
  }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });

  // TODO(FRB) on loading
  // ->its locally for testing.
  // const originActiveUserBonusData = await client.query<{
  //   userBonuses: UserBonus[];
  // }>({
  //   query: getUserBonuses,
  //   variables: {
  //     input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
  //   },
  //   fetchPolicy: 'network-only',
  // });
  // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // const activeUserBonusData: ApolloQueryResult<{
  //   userBonuses: UserBonus[];
  // }> = JSON.parse(JSON.stringify(originActiveUserBonusData));

  // activeUserBonusData.data.userBonuses.push({
  //   ...(setCurrentBonus() as UserBonus),
  //   id: freeRoundBonusId,
  //   bonusId: freeRoundBonusId,
  //   coinAmount: 1,
  //   coinValue: 100, // KRW
  //   //coinValue: 1, // EUR
  //   rounds: 2,
  //   totalWinAmount: 0,
  //   isActive: true,
  //   currentRound: 0,
  // });
  // <-its locally for testing.

  const fsBonus = activeUserBonusData.data.userBonuses.find((e) => e.bonusId != freeRoundBonusId);

  if (activeUserBonusData.data.userBonuses.length > 0) {
    const gameMode = getGameModeByBonusId(activeUserBonusData.data.userBonuses[0]!.bonusId);
    const freeSpinBonus = activeUserBonusData.data.userBonuses[0]!;
    if (
      activeUserBonusData.data.userBonuses[0]!.bonusId === bonusIds[GameMode.BUY_FEATURE] ||
      activeUserBonusData.data.userBonuses[0]!.bonusId === bonusIds[GameMode.BUY_FEATURE_MAX]
    ) {
      setBrokenBuyFeature(true);
      setCurrentBonusId(freeSpinBonus.id);

      const rounds = gameMode === GameMode.BUY_FEATURE ? 4 : 8;

      setCurrentBonus({
        ...freeSpinBonus,
        isActive: true,
        rounds: rounds,
        currentRound: 0,
        gameMode,
      });

      const fsTotalAmount = freeSpinBonus.totalWinAmount ? freeSpinBonus.totalWinAmount / freeSpinBonus.coinValue : 0;
      setCurrentFreeSpinsTotalWin(fsTotalAmount);
    } else if (fsBonus != undefined) {
      setBrokenGame(true);

      const round = fsBonus.bonusId === bonusIds[GameMode.FREE_SPINS_LVL5] ? 8 : 4;
      //GameMode.FREE_SPINS_LVL5
      // const fsTotalAmount = fsBonus?.totalWinAmount ? fsBonus?.totalWinAmount / fsBonus.coinValue : 0;
      setCurrentBonus({
        ...activeUserBonusData.data.userBonuses[0]!,
        isActive: true,
        currentRound: 0,
        totalRounds: 0,
        gameMode,
      });
      const fsTotalAmount = freeSpinBonus.totalWinAmount ? freeSpinBonus.totalWinAmount / freeSpinBonus.coinValue : 0;
      setCurrentFreeSpinsTotalWin(fsTotalAmount);

      const currentRounds = round - activeUserBonusData.data.userBonuses[0]!.rounds!;

      setCurrentBonus({
        ...setCurrentBonus(),
        currentRound: currentRounds,
        rounds: round,
        totalWinAmount: fsTotalAmount,
      });

      const totalWinAmount = calcActiveUserBonusTotalWinAmount(setCurrentBonus());
      if (totalWinAmount > 0) {
        // setCurrentFreeSpinsTotalWin2(totalWinAmount);
        eventManager.once(EventTypes.UPDATE_USER_BALANCE, () => {
          setTimeout(() => {
            eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
            // eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin2());
          });
        });
      }
    }

    const freeRoundBonus = activeUserBonusData.data.userBonuses.find((e) => e.bonusId === freeRoundBonusId);
    if (freeRoundBonus) {
      if (!fsBonus || fsBonus.data.frbReferenceId) {
        const totalWinAmount = calcActiveUserBonusTotalWinAmount(freeRoundBonus);
        setFreeRoundBonus({
          id: freeRoundBonus.id,
          bonusId: freeRoundBonus.bonusId,
          coinAmount: freeRoundBonus.coinAmount,
          coinValue: freeRoundBonus.coinValue,
          status: freeRoundBonus.status,
          rounds: freeRoundBonus.rounds,
          totalWinAmount: totalWinAmount,

          isActive: true,
          currentRound: 0,
        });
        setFreeRoundsTotalWin(totalWinAmount);
      }
    }
  }
};
