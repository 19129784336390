import { Colors } from '../../config';
import { EventTypes, GameMode, PopupOpeningTypes } from '../../global.d';
import {
  setAutoSpinsLeft,
  setGameMode,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterFeature,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsPopupOpened,
  setIsPopupOpeningInProgress,
  setIsSpinInProgress,
  setSlotMachineInitialized,
} from '../../gql/cache';
import { calcPercentage, isBaseGameMode } from '../../utils';
import { TextField } from '../components/TextField';
import { autoPlayCounterTextStyle, eventManager } from '../config';
import { UiButton } from '../ui/uiButton';

class AutoplayBtn extends UiButton {
  private count: TextField;

  private isAutoSpins = false;

  private isSpinInProgress = false;

  private isSlotBusy = false;

  private isFreeSpinsWin = false;

  private isBuyFeaturePopup = false;

  private isFadeOut = false;

  private isPopupFreeRoundsOpened = false;

  constructor() {
    super('autospin-on');
    this.interactive = !this.isDisabled;
    this.count = this.initCount();
    this.addChild(this.count.getText());
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    this.handleDisable();
  }

  private initSubscriptions(): void {
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.count.text.visible = isAutoSpins;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_AUTO_SPINS_LEFT, (autoSpinsLeft: number) => {
      this.count.text.text = `${autoSpinsLeft}`;
      this.count.text.visible = !!autoSpinsLeft && this.isAutoSpins;
      this.setScaleCountText();
    });
    eventManager.on(EventTypes.OPEN_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREE_SPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, (isBuyFeaturePopup: boolean) => {
      this.isBuyFeaturePopup = isBuyFeaturePopup;
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, (isBuyFeaturePopup: boolean) => {
      this.isBuyFeaturePopup = isBuyFeaturePopup;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FADEOUT, (isFadeOut: boolean) => {
      this.isFadeOut = isFadeOut;
      this.handleDisable();
    });
    eventManager.on(EventTypes.UI_VISIBLE, () => {
      this.visible = !this.visible;
    });
    eventManager.addListener(EventTypes.FORCE_STOP_AUTOPLAY, () => {
      if (this.isAutoSpins) {
        setAutoSpinsLeft(0);
        setIsAutoSpins(false);
        this.isAutoSpins = false;
      }
    });
    eventManager.on(EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED, (isPopupFreeRoundsOpened: boolean) => {
      this.isPopupFreeRoundsOpened = isPopupFreeRoundsOpened;
      this.handleDisable();
    });

    window.addEventListener('message', (event: MessageEvent<{ name: string }>) => {
      if (event.data?.name === 'stopAutoplay') {
        eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
      }
    });
  }

  private initCount(): TextField {
    const count = new TextField('499', 200, 100, autoPlayCounterTextStyle);
    count.text.visible = false;
    count.text.anchor.set(0.5);
    count.text.y = 0;
    count.text.x = 0;

    return count;
  }

  private setScaleCountText(): void {
    const scaleValue = this.btn.width / (110 / 100);
    this.count.text.scale.set(scaleValue / 60);
  }

  private handleUpdateIntent(): void {
    if (this.isAutoSpins) {
      this.btn.tint = Colors.GAME_COLOR;
      this.updateIntent('autospin-off');
    } else {
      this.btn.tint = Colors.WHITE_COLOR;
      this.updateIntent('autospin-on');
    }
  }

  private handleClick(): void {
    if (this.isAutoSpins) {
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
      this.isAutoSpins = false;
      this.updateIntent('autospin-on');
      if (!setIsSpinInProgress()) eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      return;
    }
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE && setSlotMachineInitialized()) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.AUTOPLAY_POPUP);

      setIsOpenAutoplayPopup(!setIsOpenAutoplayPopup());
      setIsOpenInfoPopup(false);
      setIsOpenHistoryPopup(false);
      setIsOpenBetSettingsPopup(false);
      setIsPopupOpened(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(): void {
    this.setDisable(
      !isBaseGameMode(setGameMode()) ||
        (this.isSlotBusy && !this.isAutoSpins) ||
        this.isFreeSpinsWin ||
        setIsPopupOpened() ||
        setIsContinueAutoSpinsAfterFeature() ||
        this.isPopupFreeRoundsOpened ||
        this.isBuyFeaturePopup ||
        (this.isFadeOut && !this.isAutoSpins),
    );
  }

  public override handlePosition(): void {
    this.btn.anchor.set(1, 0);
    this.count.text.anchor.set(0.5, 0);
    let size = calcPercentage(this.applicationSize.width, 7.5);
    const gap = calcPercentage(this.applicationSize.width, 1.25);

    let x = this.applicationSize.width - gap;
    let y = gap;
    this.setSize(size);
    this.count.text.y = this.btn.width;
    this.count.text.x = -this.btn.width / 2;

    if (this.isPortraitMode) {
      size = calcPercentage(this.applicationSize.height, 7.5);
      this.btn.anchor.set(0, 1);
      this.count.text.anchor.set(0.5, 1);
      x = gap;
      y = this.applicationSize.height - size - gap;
      this.setSize(size);
      this.count.text.y = -this.btn.height;
      this.count.text.x = this.btn.width / 2;
    }

    this.setScaleCountText();

    // if (this.isLandscapeMode) {}

    this.x = x;
    this.y = y;
  }
}

export default AutoplayBtn;
