import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { conversionPhoenixAnimationType, getAnimationSelectionTableLot } from '../anticipation';
import { baseGamePhoenix, phoenixAnimationType } from '../anticipation/table';
import { ISongs } from '../config';
import { BetBonusReward, BetReward, EventTypes, GameMode, ISettledBet, ReelId, UserBonus, reelSets } from '../global.d';
import {
  setBetAmount,
  setBrokenBuyFeature,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsFadeOut,
  setIsFreeSpinsWin,
  setIsMaxLevelFreeSpinsFeature,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsTimeoutErrorMessage,
  setLastRegularWinAmount,
  setNextResult,
  setPrevReelsPosition,
  setProgress,
  setReelSetId,
  setSlotConfig,
  setStressful,
  setUserBalance,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { ISlotConfig } from '../gql/d';
import { ReelSetType, getUserBonuses, isStoppedGql, slotBetGql } from '../gql/query';
import {
  getGameModeByBonusId,
  getGameModeByReelSetId,
  getLevelByGameMode,
  getScatterCount,
  getSpinResult4X5,
  isBuyFeatureMode,
  isFreeSpinsMode,
  nextTick,
  normalizeCoins,
  showCurrency,
} from '../utils';

import Animation from './animations/animation';
import AnimationChain from './animations/animationChain';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import FreeSpinBackground from './background/freeSpinBackground';
import BgmControl from './bgmControl/bgmControl';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import BuyFeatureBtnIcon from './buyFeature/buyFeatureBtnIcon';
import BuyFeaturePopup from './buyFeature/buyFeaturePopup';
import BuyFeaturePopupConfirm from './buyFeature/buyFeaturePopupConfirm';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_END_SYMBOLS_AMOUNT,
  ANTICIPATION_START_SYMBOLS_AMOUNT,
  ANTICIPATION_SYMBOLS_AMOUNT_FREE_SPINS_MODE,
  ANTICIPATION_SYMBOLS_ID,
  FREE_SPINS_LVL_MAX_CHANGES_DELAY,
  FREE_SPINS_LVL_UP_CHANGES_DELAY,
  FREE_SPINS_TIME_OUT_BANNER,
  FREE_SPINS_TIME_OUT_RETRIGGER_BANNER,
  REELS_AMOUNT,
  RETRIGGER_ANIMATION_DELAY,
  RETRIGGER_BANNER_VISIBLE_DELAY,
  RETRIGGER_MESSAGE_DELAY,
  SLOTS_PER_REEL_AMOUNT,
  SlotMachineState,
  eventManager,
} from './config';
import AutoplayBtn from './controlButtons/autoplayBtn';
import BetBtn from './controlButtons/betBtn';
import InfoBtn from './controlButtons/infoBtn';
import MenuBtn from './controlButtons/menuBtn';
import SpinBtn from './controlButtons/spinBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import { Icon } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import FlowerParticle from './particle/flowerContainer';
import Phoenix from './phoenix/phoenix';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import { Slot } from './slot/slot';
import SpinAnimation from './spin/spin';
import TintContainer from './tint/tintContainer';
import SlotsAnimationContainer from './winAnimations/slotsAnimationContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';

// const handleChangeRestriction = (): void => {
//   BgmControl.handleChangeRestriction();
// };

class SlotMachine {
  private readonly application: PIXI.Application;

  private slotConfig: ISlotConfig;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  private introSoundDelayAnimation: Animation | undefined;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public menuBtn: MenuBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  public infoBtn: InfoBtn;

  public static initSlotMachine = (
    slotConfig: ISlotConfig,
    application: PIXI.Application,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(slotConfig, application, isSpinInProgressCallback, isSlotBusyCallback);
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public reelsContainer: ReelsContainer;

  public tintContainer: TintContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public fadeArea: FadeArea;

  public background: Background;

  public freeSpinBackground: FreeSpinBackground;

  private phoenix: Phoenix;

  public bottom: BottomContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public infoBuyFeatureIcon: PIXI.Container;

  private slotsAnimationContainer: SlotsAnimationContainer;

  private particle: FlowerParticle;

  private constructor(
    slotConfig: ISlotConfig,
    application: PIXI.Application,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.slotConfig = slotConfig;
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();

    const startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotConfig.settings.startPosition;

    setPrevReelsPosition(startPosition);

    const reelSet = setUserLastBetResult().id
      ? slotConfig.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!
      : slotConfig.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)!;

    setReelSetId(reelSet!.id);

    this.reelsContainer = new ReelsContainer(reelSet!.layout, startPosition);
    this.tintContainer = new TintContainer();

    const spinResult = getSpinResult4X5({
      reelPositions: startPosition.slice(0, REELS_AMOUNT),
      reelSet: reelSet!,
      icons: slotConfig.icons,
    });

    this.miniPayTableContainer = new MiniPayTableContainer(slotConfig.icons, this.getSlotById.bind(this));
    this.miniPayTableContainer.setSpinResult(spinResult);

    this.background = new Background();
    this.freeSpinBackground = new FreeSpinBackground();
    this.particle = new FlowerParticle();
    this.application.stage.sortableChildren = true;
    this.bottom = new BottomContainer();

    this.safeArea = new SafeArea();
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();

    this.slotsAnimationContainer = new SlotsAnimationContainer();

    this.gameView = new GameView({
      winSlotsContainer: this.slotsAnimationContainer,
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      reelsContainer: this.reelsContainer,
      tintContainer: this.tintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      miniPayTableContainer: this.miniPayTableContainer,
    });
    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      this.skipAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipAnimations();
    });
    this.initBuyFeature(slotConfig.lines, this.gameView);
    this.spinBtn = new SpinBtn();

    if (setBrokenBuyFeature()) {
      setIsSlotBusy(true);
      eventManager.emit(EventTypes.SET_BROKEN_BUY_FEATURE, setIsSlotBusy());
      nextTick(() => {
        eventManager.emit(EventTypes.SET_BROKEN_BUY_FEATURE, setIsSlotBusy());
        if (this.state === SlotMachineState.IDLE) eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
      });
    }

    this.menuBtn = new MenuBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.infoBtn = new InfoBtn();

    this.infoBuyFeatureIcon = new BuyFeatureBtnIcon();
    this.fadeArea = new FadeArea();
    this.phoenix = new Phoenix();

    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);

    this.application.stage.addChild(this.background);
    this.application.stage.addChild(this.freeSpinBackground);
    this.application.stage.addChild(this.particle);
    this.safeArea.addChild(this.gameView);
    this.application.stage.addChild(this.safeArea);

    this.application.stage.addChild(this.bottom);

    this.application.stage.addChild(
      this.menuBtn,
      this.turboSpinBtn,
      this.spinBtn,
      this.betBtn,
      this.autoplayBtn,
      this.infoBtn,
    );
    this.application.stage.addChild(this.fadeArea);

    if (setBrokenGame()) {
      this.onBrokenGame();
    }

    this.application.stage.addChild(this.phoenix);
  }

  private initBuyFeature(lines: number[], view: GameView): void {
    view.addChild(new BuyFeatureBtn(), new Backdrop(), new BuyFeaturePopup(lines), new BuyFeaturePopupConfirm());
  }

  private onBrokenGame(): void {
    const gameMode = getGameModeByBonusId(setCurrentBonus().bonusId);
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setReelSetId(setCurrentBonus().reelSetId);

    if (setCurrentFreeSpinsTotalWin()) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
    } else {
      eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    }

    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });
    eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
      text: 'freeSpinsTitleText',
      spins: setCurrentBonus().rounds,
      currentSpin: setCurrentBonus().currentRound,
    });

    if (
      setUserLastBetResult().reelSetId === ReelId.REGULAR ||
      setUserLastBetResult().reelSetId === ReelId.BUY_FEATURE ||
      setUserLastBetResult().reelSetId === ReelId.BUY_FEATURE_MAX
    ) {
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        title: i18n.t('freeSpinsTitle', { gameMode }),
        subTitle: i18n.t('freeSpinsText'),
        freeSpinsSubtitle:
          setIsMaxLevelFreeSpinsFeature() || setGameMode() === GameMode.FREE_SPINS_LVL5
            ? i18n.t('freeSpinsMaxSubtitle')
            : i18n.t('freeSpinsSubtitle'),
        btnText: i18n.t('startText'),
        callback: () => {
          setCurrentBonus({
            ...setCurrentBonus(),
            isActive: true,
            totalRounds: setCurrentBonus().totalRounds,
            currentRound: 0,
          });
          this.setState(SlotMachineState.IDLE);
        },
        winTitle: '',
        winTotalSpins: '',
        isLevelMax: setIsMaxLevelFreeSpinsFeature() || setGameMode() === GameMode.FREE_SPINS_LVL5 ? true : false,
      });
    } else {
      if (setProgress().wasLoaded) {
        this.setState(SlotMachineState.IDLE);
      } else {
        eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
          this.setState(SlotMachineState.IDLE);
        });
      }
    }
  }

  private initListeners(): void {
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, this.resetSlotMachine.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.addListener(EventTypes.END_RETRIGGER_FEATURE, this.onRetriggerEnd.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.COUNT_UP_END, this.onCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, this.handleError.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      BgmControl.playBgmGameMode(getLevelByGameMode(setGameMode()));
      if (setIsDuringBigWinLoop()) {
        AudioApi.play({ type: ISongs.Win_Loop });
      }
    });

    eventManager.addListener(EventTypes.SET_SLOT_BUSY_DISABLE, () => {
      this.isSlotBusyCallback();
    });
    eventManager.addListener(EventTypes.CREATE_END_MESSAGE, this.createEndMessage.bind(this));
    eventManager.addListener(EventTypes.FREE_SPIN, this.spin.bind(this));
    eventManager.addListener(EventTypes.SET_STATE, this.setState.bind(this));
    eventManager.addListener(EventTypes.START_BUY_FEATURE_ROUND, this.startBuyFeature.bind(this));
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private startBuyFeature(): void {
    let gameMode: GameMode;
    if (setIsMaxLevelFreeSpinsFeature()) {
      gameMode = GameMode.BUY_FEATURE_MAX;
    } else {
      gameMode = GameMode.BUY_FEATURE;
    }
    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: GameMode.BUY_FEATURE,
      reelPositions: [0, 0, 0, 0, 0],
      reelSetId: reelSets[gameMode]!,
    });
  }

  private createEndMessage(): void {
    this.endFreeSpins();
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode: number = getLevelByGameMode(setGameMode());
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      setGameMode(settings.mode);

      if (settings.mode === GameMode.REGULAR) {
        setReelSetId(settings.reelSetId);

        const reelSet = setSlotConfig().reels.find((reels) => reels.id === settings.reelSetId);
        const spinResult = getSpinResult4X5({
          reelPositions: settings.reelPositions.slice(0, 5),
          reelSet: reelSet!,
          icons: setSlotConfig().icons,
        });
        setPrevReelsPosition(settings.reelPositions.slice(0, 5));
        this.miniPayTableContainer.setSpinResult(spinResult);

        eventManager.emit(EventTypes.CHANGE_REEL_SET, {
          reelSet: setSlotConfig().reels.find((reels) => reels.id === settings.reelSetId)!,
          reelPositions: settings.reelPositions,
        });
        eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
      }
    }
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);

    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
        totalRounds: 0,
      });
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency: showCurrency(setCurrency()),
        }),
      );
      eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);

      this.setState(SlotMachineState.IDLE);
      this.introSoundDelayAnimation?.skip();
    } else if (isFreeSpinsMode(settings.mode)) {
      const bonus = this.getFreeSpinBonus();

      if (!bonus) throw new Error('Something went wrong');
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());

      if (settings.isRetrigger) {
        setCurrentBonus({
          ...bonus,
          totalRounds: setCurrentBonus().totalRounds,
        });

        if (previousGameMode !== settings.mode) {
          const chain = new AnimationChain();
          const retriggerBannerVisibleDelay = Tween.createDelayAnimation(RETRIGGER_BANNER_VISIBLE_DELAY);
          retriggerBannerVisibleDelay.addOnComplete(() => {
            eventManager.emit(EventTypes.CREATE_RETRIGGER_MESSAGE_BANNER, {
              title: i18n.t('freeSpinsTitle', { currentGameMode }),
              subTitle: i18n.t('freeSpinsText'),
              freeSpinsSubtitle: i18n.t('freeSpinsSubtitle'),
              btnText: i18n.t('startText'),
              level: getLevelByGameMode(settings.mode),
              callback: () => chain.skip(),
              winTitle: '',
              winTotalSpins: '',
              lastLevelRetriger: false,
            });
          });

          const levelUpChangesDelay = Tween.createDelayAnimation(FREE_SPINS_LVL_UP_CHANGES_DELAY);
          const levelUpChangesUpdate = () => {
            AudioApi.play({ type: ISongs.LvUpIconSE });
            eventManager.emit(EventTypes.SET_NEXT_FREE_SPINS_LEVEL, {
              mode: currentGameMode,
            });
            eventManager.emit(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE, bonus.rounds, 0, true);
            AudioApi.play({ type: ISongs.LvUpIconSE });
          };

          levelUpChangesDelay.addOnStart(() => {
            retriggerBannerVisibleDelay.start();
          });
          levelUpChangesDelay.addOnComplete(levelUpChangesUpdate);
          levelUpChangesDelay.addOnSkip(levelUpChangesUpdate);

          const retriggerBannerTime = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_RETRIGGER_BANNER);
          chain.appendAnimation(levelUpChangesDelay);
          chain.appendAnimation(retriggerBannerTime);
          const callback = () => {
            setCurrentBonus({
              ...bonus,
              totalRounds: setCurrentBonus().totalRounds,
              isActive: true,
              currentRound: 0,
            });
            this.setState(SlotMachineState.IDLE);
          };
          chain.addOnComplete(() => {
            eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
            callback();
          });
          chain.addOnSkip(() => {
            eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
            callback();
          });
          chain.start();
        } else {
          const levelUpChangesDelay = Tween.createDelayAnimation(FREE_SPINS_LVL_MAX_CHANGES_DELAY);
          levelUpChangesDelay.addOnComplete(() => {
            eventManager.emit(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE, bonus.rounds, 0, true);
            AudioApi.play({ type: ISongs.LvUpIconSE });

            setCurrentBonus({
              ...bonus,
              isActive: true,
              currentRound: 0,
              totalRounds: setCurrentBonus().totalRounds,
            });
            this.setState(SlotMachineState.IDLE);
          });
          levelUpChangesDelay.start();
        }
      } else {
        setCurrentBonus({ ...bonus, totalRounds: 0 });
        eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
        eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
          text: 'freeSpinsTitleText',
          spins: bonus.rounds,
          currentSpin: 0,
        });

        if (!setIsContinueAutoSpinsAfterFeature()) {
          eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
            title: i18n.t('freeSpinsTitle', { currentGameMode }),
            subTitle: i18n.t('freeSpinsText'),
            freeSpinsSubtitle:
              setIsMaxLevelFreeSpinsFeature() || setGameMode() === GameMode.FREE_SPINS_LVL5
                ? i18n.t('freeSpinsMaxSubtitle')
                : i18n.t('freeSpinsSubtitle'),
            btnText: i18n.t('startText'),
            callback: () => {
              setCurrentBonus({
                ...bonus,
                isActive: true,
                totalRounds: setCurrentBonus().totalRounds,
                currentRound: 0,
              });
              this.setState(SlotMachineState.IDLE);
            },
            winTitle: '',
            winTotalSpins: '',
            isLevelMax: setIsMaxLevelFreeSpinsFeature() || setGameMode() === GameMode.FREE_SPINS_LVL5 ? true : false,
          });
        } else {
          setCurrentBonus({
            ...bonus,
            isActive: true,
            totalRounds: 0,
            currentRound: 0,
          });
          this.setState(SlotMachineState.IDLE);
        }
      }
      BgmControl.playBgmGameMode(currentGameMode);
    }
    if (this.nextResult) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
    }
  }

  private startFreeSpins(): void {
    setIsFreeSpinsWin(true);
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode: setIsMaxLevelFreeSpinsFeature() ? GameMode.FREE_SPINS_LVL5 : GameMode.FREE_SPINS_LVL1,
      reelPositions: this.nextResult?.bet.result.reelPositions!,
      reelSetId: setIsMaxLevelFreeSpinsFeature()
        ? reelSets[GameMode.FREE_SPINS_LVL5]!
        : reelSets[GameMode.FREE_SPINS_LVL1]!,
      fadeOutDuration: 1000,
      fadeInDuration: 1000,
    });
  }

  private async endFreeSpins(): Promise<void> {
    const res = await client.query<{
      userBonuses: UserBonus[];
    }>({
      query: getUserBonuses,
      variables: { input: { id: setCurrentBonus().id } },
      fetchPolicy: 'network-only',
    });
    const { betId } = res.data.userBonuses[0]!;
    const bet = await client.query<ISettledBet>({
      query: slotBetGql,
      variables: { input: { id: betId } },
      fetchPolicy: 'network-only',
    });

    const { reelPositions, reelSetId } = {
      reelPositions: bet.data.bet.result.reelPositions,
      reelSetId: bet.data.bet.reelSetId,
    };

    AudioApi.play({ type: ISongs.TotalWinBanner, stopPrev: true });

    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    setIsMaxLevelFreeSpinsFeature(false);

    const callback = () => {
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.REGULAR,
        reelSetId,
        reelPositions,
        fadeOutDuration: 1000,
        fadeInDuration: 1000,
      });
    };
    const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
    delay.addOnComplete(() => {
      callback();
    });

    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    this.skipAnimations();

    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        winTitle: i18n.t<string>('freeSpinsTotalWinTitle'),
        totalWin: `${formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency: showCurrency(setCurrency()),
        })} `,
        totalSpins: setCurrentBonus().totalRounds,
        level: getLevelByGameMode(setGameMode()),
        winTotalSpins: i18n.t('freeSpinsTotalSpins'),
        preventDefaultDestroy: true,
        callback,
        title: '',
        subTitle: '',
        btnText: '',
        freeSpinsSubtitle: '',
      });
    } else {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        title: i18n.t('freeSpinsTotalWinTitle'),
        totalWin: `${formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency: showCurrency(setCurrency()),
        })}`,
        totalSpins: setCurrentBonus().totalRounds,
        level: getLevelByGameMode(setGameMode()),
        winTotalSpins: i18n.t('freeSpinsTotalSpins'),
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
        subTitle: '',
        btnText: '',
        freeSpinsSubtitle: '',
        winTitle: '',
      });
    }

    setBrokenGame(false);
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsRevokeThrowingError(true);
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  private registerAnimator(animator: () => void, priority?: number) {
    if (priority !== undefined) {
      this.application.ticker.add(animator, undefined, priority);
    } else {
      this.application.ticker.add(animator);
    }
  }

  private removeAnimator(animator: () => void) {
    this.application.ticker.remove(animator);
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0]!.spinAnimation?.getFakeRolling().removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(total: number, current: number): void {
    eventManager.emit(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE, current, total, false);
  }

  public spin(isTurboSpin: boolean | undefined): void {
    this.reelsContainer.forcedStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        if (!this.isReadyForStop) {
          this.isReadyForStop = true;
          if (isFreeSpinsMode(setGameMode()) || isBuyFeatureMode(setGameMode())) {
            this.updateFreeSpinsAmount(setCurrentBonus().currentRound, setCurrentBonus().rounds);
          }
          this.removeErrorHandler();
          this.dynamicReelSetChange(this.nextResult!.bet.reelSet.id);

          eventManager.emit(
            EventTypes.SETUP_REEL_POSITIONS,
            this.nextResult.bet.result.reelPositions,
            getScatterCount(this.nextResult.bet.result.spinResult),
            this.getAnticipationStartReelId(this.nextResult.bet.result.spinResult),
            this.getAnticipationEndReelId(this.nextResult.bet.result.spinResult),
          );
          eventManager.emit(EventTypes.UPDATE_FREE_SPIN_ROUND);
        }
        eventManager.emit(EventTypes.SETUP_BET_RESULT, this.nextResult);
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      this.skipAnimations();
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);

      this.isStopped = false;
      this.isReadyForStop = false;
      this.nextResult = null;
      this.setState(SlotMachineState.SPIN);
      const spinAnimation = this.getSpinAnimation(!isFreeSpinsMode(setGameMode()) && !!isTurboSpin);

      if (isFreeSpinsMode(setGameMode())) {
        const bonus = setCurrentBonus();
        bonus.currentRound += 1;
        bonus.totalRounds += 1;
        setCurrentBonus(bonus);
      }
      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i]!;
      const spinAnimation: SpinAnimation = reel.createSpinAnimation(isTurboSpin);

      if (i === 0) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            if (isFreeSpinsMode(setGameMode())) {
              this.updateFreeSpinsAmount(setCurrentBonus().currentRound, setCurrentBonus().rounds);
            }
            this.dynamicReelSetChange(this.nextResult!.bet.reelSet.id);
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              getScatterCount(this.nextResult.bet.result.spinResult),
              this.getAnticipationStartReelId(this.nextResult.bet.result.spinResult),
              this.getAnticipationEndReelId(this.nextResult.bet.result.spinResult),
            );
            eventManager.emit(EventTypes.UPDATE_FREE_SPIN_ROUND);
            eventManager.emit(EventTypes.SETUP_BET_RESULT, this.nextResult);
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i]!.isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() => {
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin);
          });
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private getBonusFromResult(): UserBonus | undefined {
    return (
      this.nextResult?.rewards.find(
        // eslint-disable-next-line no-underscore-dangle
        (reward) => reward.__typename === 'BetBonusReward',
      ) as BetBonusReward
    )?.userBonus;
  }

  public getFreeSpinBonus(): UserBonus | undefined {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';
    return this.nextResult?.rewards.filter(isBonusReward).find((reward) => {
      return reward.userBonus?.bonus.type === 'FREE_SPIN';
    })?.userBonus;
  }

  private onCountUpEnd(): void {
    this.isSpinInProgressCallback();
    const bonus = this.getFreeSpinBonus();
    const mode = setGameMode();
    if (bonus) {
      if (mode === GameMode.REGULAR || isBuyFeatureMode(mode)) {
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setCurrentBonus({
          ...bonus,
          isActive: true,
          currentRound: 0,
          totalRounds: 0,
        });
        setCurrentFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
        setWinAmount(this.nextResult?.bet.result.winCoinAmount);
        eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
        this.startFreeSpins();
        this.setState(SlotMachineState.IDLE);
      } else {
        const reTriggerAnimationDelay = Tween.createDelayAnimation(RETRIGGER_ANIMATION_DELAY);
        const reTriggerMessageDelay = Tween.createDelayAnimation(RETRIGGER_MESSAGE_DELAY);
        reTriggerAnimationDelay.addOnStart(() => {
          if (mode !== GameMode.FREE_SPINS_LVL5) {
            eventManager.emit(EventTypes.START_RETRIGGER_ANIMATION);
          }

          setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
        });
        reTriggerAnimationDelay.addOnComplete(() => {
          eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
        });
        reTriggerMessageDelay.addOnComplete(() => {
          this.onRetrigger(bonus);
        });
        reTriggerAnimationDelay.start();
        reTriggerMessageDelay.start();
      }
    } else {
      if (mode === GameMode.REGULAR) {
        setWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
      }
      if (isFreeSpinsMode(mode)) {
        setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      }
      this.setState(SlotMachineState.IDLE);
    }
    if (this.nextResult) eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
  }

  private dynamicReelSetChange(reelId: string): void {
    if (setReelSetId() !== reelId) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find((reels) => reels.id === reelId)!,
        reelPositions: [0, 0, 0, 0, 0],
      });
      setReelSetId(reelId);
    }
  }

  private onRetrigger(userBonus: UserBonus | undefined): void {
    // todo throw error correctly
    if (userBonus === undefined) throw new Error('Bonus not found');
    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: getGameModeByReelSetId(userBonus.bonus.reelSetId)!,
      reelPositions: this.nextResult?.bet.result.reelPositions!,
      reelSetId: userBonus.bonus.reelSetId,
      isRetrigger: true,
    });
  }

  private onRetriggerEnd(): void {
    this.updateFreeSpinsAmount(setCurrentBonus().currentRound, setCurrentBonus().rounds);
    this.setState(SlotMachineState.IDLE);
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
  }

  private getAnticipationEndReelId(spinResult: Icon[]): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_END_SYMBOLS_AMOUNT[i];
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
          // eslint-disable-next-line no-plusplus
          if (spinResult[j + REELS_AMOUNT * i]!.id === symbolId) currentCount++;
        }
        if (currentCount >= count!) minReelId = Math.min(minReelId, j);
      }
    });
    return minReelId + 1;
  }

  private getAnticipationStartReelId(spinResult: Icon[]): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_START_SYMBOLS_AMOUNT[i];
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
          // eslint-disable-next-line no-plusplus
          if (spinResult[j + REELS_AMOUNT * i]!.id === symbolId) currentCount++;
        }

        if (currentCount >= count!) minReelId = Math.min(minReelId, j);
      }
    });

    if (setNextResult()!.bet.result.winCoinAmount / setBetAmount() >= 500) {
      const AnimationPtn = conversionPhoenixAnimationType(
        getAnimationSelectionTableLot(setNextResult()!.bet.id, baseGamePhoenix),
      );
      if (AnimationPtn === phoenixAnimationType.PHOENIX) {
        eventManager.emit(EventTypes.PHOENIX_START);
      }
    }

    return minReelId;
  }

  private getAnticipationReelId(spinResult: Icon[]): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_SYMBOLS_AMOUNT_FREE_SPINS_MODE[i];
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        if (spinResult[j + REELS_AMOUNT * 0]!.id === symbolId) currentCount += 1;
        if (spinResult[j + REELS_AMOUNT * 1]!.id === symbolId) currentCount += 1;
        if (spinResult[j + REELS_AMOUNT * 2]!.id === symbolId) currentCount += 1;

        if (currentCount >= count!) minReelId = Math.min(minReelId, j);
      }
    });

    if (setNextResult()!.bet.result.winCoinAmount / setBetAmount() >= 500) {
      const AnimationPtn = conversionPhoenixAnimationType(
        getAnimationSelectionTableLot(setNextResult()!.bet.id, baseGamePhoenix),
      );
      if (AnimationPtn === phoenixAnimationType.PHOENIX) {
        eventManager.emit(EventTypes.PHOENIX_START);
      }
    }

    return minReelId;
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    const spinResult = getSpinResult4X5({
      reelPositions: result.bet.result.reelPositions.slice(0, REELS_AMOUNT),
      reelSet: setSlotConfig().reels.find((reelSet) => reelSet.id === result.bet.reelSet.id)!,
      icons: setSlotConfig().icons,
    });
    result.bet.result.spinResult = spinResult;
    this.nextResult = result;

    setPrevReelsPosition(result.bet.result.reelPositions.slice(0, REELS_AMOUNT));
    setNextResult(result);

    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult.balance.placed);
    setUserBalance({ ...setUserBalance(), balance: result.balance.placed });
  }

  public onSpinStop(_isTurboSpin: boolean | undefined): void {
    if (setBrokenBuyFeature()) {
      setBrokenBuyFeature(false);
    }
    this.miniPayTableContainer.setSpinResult(this.nextResult!.bet.result.spinResult);
    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x]!.slots[
      (2 * this.reelsContainer.reels[x]!.data.length - this.reelsContainer.reels[x]!.position + y - 1) %
        this.reelsContainer.reels[x]!.data.length
    ]!;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    eventManager.emit(EventTypes.RESIZE_UI_BUTTON, width, height);
  }

  private setState(state: SlotMachineState): void {
    if (state === SlotMachineState.IDLE && this.state === SlotMachineState.IDLE && !isFreeSpinsMode(setGameMode())) {
      return;
    }
    this.state = state;

    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isFreeSpinsMode(setGameMode()) ? false : state === 0);
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  public hasWin() {
    return this.nextResult!.bet.result.winCoinAmount > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE || setIsFreeSpinsWin() || setIsContinueAutoSpinsAfterFeature() || setIsFadeOut(),
    );

    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }

      if (isFreeSpinsMode(setGameMode())) {
        if (setCurrentBonus().isActive && setCurrentBonus().rounds === setCurrentBonus().currentRound) {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          this.endFreeSpins();
        } else {
          this.skipAnimations();
          setTimeout(
            () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),
            setCurrentBonus().currentRound === 0 ? 0 : 500,
          );
        }
      }
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.getFreeSpinBonus()) {
        if (isFreeSpinsMode(setGameMode())) {
          eventManager.emit(EventTypes.JINGLE_START);
          // const jingleDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.LvUp].duration);
          const jingleDelay = Tween.createDelayAnimation(1000);
          jingleDelay.addOnStart(() => {
            AudioApi.play({ type: ISongs.LvUp, stopPrev: true });
          });
          jingleDelay.addOnComplete(() => {
            this.setState(SlotMachineState.WINNING);
          });
          jingleDelay.start();
        } else {
          // const jingleDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.FeatureTrigger].duration);
          const jingleDelay = Tween.createDelayAnimation(1000);
          jingleDelay.addOnStart(() => {
            AudioApi.play({ type: ISongs.FeatureTrigger, stopPrev: true });
          });
          jingleDelay.addOnComplete(() => {
            this.setState(SlotMachineState.WINNING);
          });
          jingleDelay.start();
        }
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      eventManager.emit(EventTypes.SPIN_END);
      if (this.hasWin()) {
        eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, false, false, false);
      } else {
        this.onCountUpEnd();
      }
    }
  }
}

export default SlotMachine;
